import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import TextInput from "components/Forms/TextInput";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { useTranslation } from "react-i18next";
import { AWSReducer, DefaultAWSData } from "./aws-reducer";
import BillingCompanyDropdown from "components/Forms/BillingCompanyDropdown";
import BillingSubCompanyDropdown from "components/Forms/BillingSubCompanyDropdown";
import BillingDepartmentDropdown from "components/Forms/BillingDepartmentDropdown";
import StaticDropdown from "components/Forms/StaticDropdown";
import {
  aiUsageTypes,
  applicationTypes,
  azureModelTypes,
  bedrockModelTypes,
  businessJustifications,
  categoryOfDataOption,
  cloudTypeData,
  dataTypeOptions,
  distributedOutputToolsOption,
  llmModelAws,
  llmModelAzure,
  llmModelGcp,
  programmingLanguages,
  qualityControlConsents,
  regionOption,
  scopeOfUsers,
  staticbillingRegion,
  staticbillto,
  toolsDistribution,
  typeOfDataProcessed,
  typeOfEnvironments,
  typeOfTasks,
} from "static-contents/ai-request-data";
import { useSelector } from "react-redux";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";
import { aiRequestValidate } from "../validator/aiRequestValidate";
import NewRequestSubmit from "./NewRequestSubmit";
import NewRequestCancel from "./NewRequestCancel";
import CustomModal from "components/Modal/CustomModal";
import ShowWarningText from "./ShowWarningText";

const NewRequest = () => {
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  const { t } = useTranslation();
  const ref = useRef();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  //fileUploadApiUrl
  const fileUploadApiUrl = `${process.env.REACT_APP_AI_FILE_UPLOAD}`;

  const isFieldEnable =
    formData?.aiRequestDetails?.cloudService === "Amazon Q Business" ||
    formData?.aiRequestDetails?.cloudService === "AWS Bedrock" ||
    formData?.aiRequestDetails?.cloudService === "Azure OpenAI";
  const [fileName, setFileName] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [baseFile, setBaseFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billto, setBillto] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  const [isCloudAccount, setIsCloudAccount] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [showEnvironmentText, setShowEnvironmentText] = useState(false);
  const [noteEnvironmentText, setNoteEnvironmentText] = useState(null);

  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSubmitRefUid, setFormSubmitRefUid] = useState(null);

  const handleChange = ({ target }) => {
    if (target.name === "billingOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: "",
          [`billingDepartment`]: "",
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingOpco`]: opCompany,
        },
      });
      if (opCompany !== "") {
        const getSubOpCompany = responseData.find((t) => t.opco === opCompany)
          .subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === "billingSubOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: "",
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: opSubCompany,
        },
      });
      if (opSubCompany !== "") {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    if (target.name === "billingDepartment") {
      let department = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: department,
        },
      });
    }
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: { [target.name]: target.value },
    });
    if (target.name === "typeOfEnvironment") {
      if (target.value === "Development" || target.value === "Production") {
        setShowEnvironmentText(true);
        setNoteEnvironmentText(
          "The request will be reviewed by Infosec and Risk Team"
        );
      } else if (target.value === "Playground (POC Purpose)") {
        setShowEnvironmentText(true);
        setNoteEnvironmentText("The request will be reviewed by Infosec team");
      } else {
        setShowEnvironmentText(false);
      }
    }
  };

  const handleChangeTextArea = (name, value) => {
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: {
        [name]: value,
      },
    });
  };
  const handleChangeRadio = (checkedValue) => {
    setIsCloudAccount(checkedValue);
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: {
        [`haveCloudAccount`]: checkedValue === 0 ? true : false,
        [`dontHaveCloudAccount`]: checkedValue === 1 ? true : false,
      },
    });
  };
  useEffect(() => {
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: {
        [`globalId`]: userInfo?.preferred_username,
        [`email`]: userInfo?.email,
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0
      }
    }`;
    const encodedString = Buffer.from(queryObj).toString("base64");
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
  }, []);

  const uploadImage = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ["pdf", "png", "jpg"];
    const fileExtension = filePath.split(".").pop();

    if (checkFileSize >= 5120) {
      alert("File size should be below 5MB");
      ref.current.value = "";
    } else if (!allowedExtensions.includes(fileExtension)) {
      alert("file type not allowed");
      ref.current.value = "";
    } else {
      const base64 = await convertBase64(file);
      setBaseFile(base64);
      setFileName(file.name);

      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`supportingDocumentName`]: file.name,
          [`documentBaseFile`]: base64,
        },
      });
      setFileLoading(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const handleFileUpload = (
    currentEpochTime,
    { supportingDocumentName, documentBaseFile }
  ) => {
    const requestBody = {
      supportingDocumentName: supportingDocumentName,
      documentBaseFile: documentBaseFile,
      uid: `u-${currentEpochTime}`,
    };
    postApiCall(fileUploadApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data.statusCode === 200) {
        } else {
          setFormSubmitError(
            "Failed to save, Contact Sony Global Cloud Team for Assistance"
          );
        }
      })
      .catch((err) => {
        setLoading(true);
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };

  const handleAddFormApi = (
    formSubmitApiUrl,
    idToken,
    requestBody,
    currentEpochTime
  ) => {
    postApiCall(formSubmitApiUrl, idToken, requestBody)
      .then((response) => {
        // console.log("saveapi response", response);
        if (response.data.status === "Success") {
          setFormSubmitRefUid(`u-${currentEpochTime}`);
          setFormSubmitSuccess(true);
          setFormSubmitLoading(false);
          setLoading(false);
        } else {
          setFormSubmitError(
            "Failed to save, Contact Sony Global Cloud Team for Assistance"
          );
        }
      })
      .catch((err) => {
        setFormSubmitLoading(false);
        setLoading(false);
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = aiRequestValidate(formData.aiRequestDetails);

    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      setFormSubmitLoading(true);

      // Form Submission
      const currentEpochTime = Date.now();
      const formSubmitApiUrl = `${process.env.REACT_APP_AI_REQUEST_ADD}`;

      // Requestbody;
      if (formData?.aiRequestDetails?.supportingDocumentName !== "") {
        handleFileUpload(currentEpochTime, formData.aiRequestDetails);
      }

      const requestBody = {
        globalId: formData?.aiRequestDetails?.globalId,
        email: formData?.aiRequestDetails?.email,
        applicationName: formData?.aiRequestDetails?.applicationName,
        // haveCloudAccount: formData?.aiRequestDetails?.haveCloudAccount,
        gcsapproval: "Pending",
        infosecapproval: "Pending",
        riskapproval: "Pending",
        overallapproval: "Pending",

        useCaseDescription: formData?.aiRequestDetails?.useCaseDescription,
        cloudService: formData?.aiRequestDetails?.cloudService,

        additionalComments: formData?.aiRequestDetails?.additionalComments,
        supportingDocumentName:
          formData?.aiRequestDetails?.supportingDocumentName,
        // documentBaseFile: formData?.aiRequestDetails?.documentBaseFile,
        // requestStatus: "Pending",
        uid: `u-${currentEpochTime}`,
        submittedBy: userInfo?.email,

        //newly added fields

        applicationType: formData?.aiRequestDetails?.applicationType,
        applicationType_others:
          formData?.aiRequestDetails?.applicationType_others,
        region: formData?.aiRequestDetails?.region,

        scopeOfUsers: formData?.aiRequestDetails?.scopeOfUsers,
        typeOfDataProcessed: formData?.aiRequestDetails?.typeOfDataProcessed,
        typeOfTasks: formData?.aiRequestDetails?.typeOfTasks,
        typeOfTasks_others: formData?.aiRequestDetails?.typeOfTasks_others,
        aiUsageType: formData?.aiRequestDetails?.aiUsageType,
        toolsDistribution: formData?.aiRequestDetails?.toolsDistribution,
        businessJustification:
          formData?.aiRequestDetails?.businessJustification,
        qualityControlConsent:
          formData?.aiRequestDetails?.qualityControlConsent,
        typeOfEnvironment: formData?.aiRequestDetails?.typeOfEnvironment,
        cloudAccountNumber: formData?.aiRequestDetails?.cloudAccountNumber,
        dataTypeUsedForPOC: formData?.aiRequestDetails?.dataTypeUsedForPOC,
        categoryOfData: formData?.aiRequestDetails?.categoryOfData,
        distributedOutputTools:
          formData?.aiRequestDetails?.distributedOutputTools,
      };

      if (formData?.aiRequestDetails?.cloudService === "Amazon Q Developer") {
        requestBody.programmingLanguage =
          formData?.aiRequestDetails?.programmingLanguage;
      }
      if (
        formData?.aiRequestDetails?.cloudService === "AWS Bedrock" ||
        formData?.aiRequestDetails?.cloudService === "Azure OpenAI"
      ) {
        requestBody.llmModel = formData?.aiRequestDetails?.llmModel;
      }
      if (formData?.aiRequestDetails?.haveCloudAccount) {
        requestBody.cloudAccountNumber =
          formData?.aiRequestDetails?.cloudAccountNumber;
        requestBody.billingEmail =
          formData?.aiRequestDetails?.billingEmailAccoutNo;
      } else {
        requestBody.billingOpco = formData?.aiRequestDetails?.billingOpco;
        requestBody.billingSubOpco = formData?.aiRequestDetails?.billingSubOpco;
        requestBody.billingDepartment =
          formData?.aiRequestDetails?.billingDepartment;
        requestBody.billTo = formData?.aiRequestDetails?.billTo;
        requestBody.billingRegion = formData?.aiRequestDetails?.billingRegion;
        requestBody.billingEmail = formData?.aiRequestDetails?.billingEmail;
      }

      if (formData?.aiRequestDetails?.billTo === "GSID") {
        requestBody.gsid = formData?.aiRequestDetails?.gsid;
      } else if (formData?.aiRequestDetails?.billTo === "Global IS (GISC)") {
        requestBody.billingIo = formData?.aiRequestDetails?.billingIo;
      } else if (formData?.aiRequestDetails?.billTo === "Other Teams") {
        requestBody.costCenter = formData?.aiRequestDetails?.costCenter;
      }

      // console.log("first", requestBody);
      setTimeout(() => {
        handleAddFormApi(
          formSubmitApiUrl,
          idToken,
          requestBody,
          currentEpochTime
        );
      }, 5000);
    }
  };

  return (
    <>
      <Row>
        <Col md={12} className=" mb-4 pb-1 text-center">
          <span className="page-content-title">{t("AI Service Request")}</span>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <SpinnerLoader />}

        {/* {formSubmitError !== null && (
    <Row>
      <Col className='mt-2'>
        <DangerAlert message={formSubmitError} />
      </Col>
    </Row>
  )} */}

        {loading === false && (
          <>
            <Row>
              <Col md={6}>
                <TextInput
                  isReadOnly={true}
                  label={t("Global ID")}
                  placeholder={t("Global ID")}
                  name="globalId"
                  value={formData?.aiRequestDetails?.globalId}
                  onChange={(e) => handleChange(e)}
                  isInvalid={dataInputError.globalId ? true : false}
                  invalidMessage={dataInputError.globalId}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  isReadOnly={true}
                  label={t("Email Address")}
                  name="email"
                  value={formData?.aiRequestDetails?.email}
                  onChange={(e) => handleChange(e)}
                  placeholder={t("Email Address")}
                  isInvalid={dataInputError.email ? true : false}
                  invalidMessage={dataInputError.email}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={t("Select Gen AI Service")}
                  defaultSelectMessage="Select Service"
                  name="cloudService"
                  value={formData?.aiRequestDetails?.cloudService}
                  onChange={(e) => handleChange(e)}
                  selectValues={cloudTypeData}
                  isInvalid={dataInputError.cloudService ? true : false}
                  invalidMessage={dataInputError.cloudService}
                />
              </Col>
              {(formData?.aiRequestDetails?.cloudService === "AWS Bedrock" ||
                formData?.aiRequestDetails?.cloudService ===
                  "Azure OpenAI") && (
                <Col md={6}>
                  <StaticDropdown
                    label={t("Select Model")}
                    defaultSelectMessage="Select"
                    name="llmModel"
                    value={formData?.aiRequestDetails?.llmModel}
                    onChange={(e) => handleChange(e)}
                    selectValues={
                      formData?.aiRequestDetails?.cloudService === "AWS Bedrock"
                        ? bedrockModelTypes
                        : formData?.aiRequestDetails?.cloudService ===
                            "Azure OpenAI" && azureModelTypes
                    }
                    isInvalid={dataInputError.llmModel ? true : false}
                    invalidMessage={dataInputError.llmModel}
                  />
                </Col>
              )}
              {formData?.aiRequestDetails?.cloudService ===
                "Amazon Q Developer" && (
                <Col md={6}>
                  <StaticDropdown
                    label={t(
                      "Provide the primary programming language used for your app"
                    )}
                    defaultSelectMessage="Select"
                    name="programmingLanguage"
                    value={formData?.aiRequestDetails?.programmingLanguage}
                    onChange={(e) => handleChange(e)}
                    selectValues={programmingLanguages}
                    isInvalid={
                      dataInputError.programmingLanguage ? true : false
                    }
                    invalidMessage={dataInputError.programmingLanguage}
                  />
                </Col>
              )}
              {isFieldEnable && (
                <>
                  {formData?.aiRequestDetails?.cloudService ===
                    "Amazon Q Business" && (
                    <Col md={6}>
                      <TextInput
                        label={t("Application Name")}
                        name="applicationName"
                        value={formData?.aiRequestDetails?.applicationName}
                        onChange={(e) => handleChange(e)}
                        placeholder={t("Application Name")}
                        isInvalid={
                          dataInputError.applicationName ? true : false
                        }
                        invalidMessage={dataInputError.applicationName}
                      />
                    </Col>
                  )}
                  {formData?.aiRequestDetails?.cloudService ===
                    "Amazon Q Business" && (
                    <Col md={6}>
                      <StaticDropdown
                        label={t("Region")}
                        defaultSelectMessage="Select"
                        name="region"
                        value={formData?.aiRequestDetails?.region}
                        onChange={(e) => handleChange(e)}
                        selectValues={regionOption}
                        isInvalid={dataInputError.region ? true : false}
                        invalidMessage={dataInputError.region}
                      />
                    </Col>
                  )}
                  <Col
                    md={`${
                      formData?.aiRequestDetails?.applicationType ===
                      "Others (please specify)"
                        ? 6
                        : 6
                    }`}
                  >
                    <StaticDropdown
                      label={t("AI Feature Usage")}
                      defaultSelectMessage="Select"
                      name="applicationType"
                      value={formData?.aiRequestDetails?.applicationType}
                      onChange={(e) => handleChange(e)}
                      selectValues={applicationTypes}
                      isInvalid={dataInputError.applicationType ? true : false}
                      invalidMessage={dataInputError.applicationType}
                    />
                  </Col>
                  {formData?.aiRequestDetails?.applicationType ===
                    "Others (please specify)" && (
                    <Col md={6}>
                      <TextInput
                        label={t("Others (please specify)")}
                        name="applicationType_others"
                        value={
                          formData?.aiRequestDetails?.applicationType_others
                        }
                        onChange={(e) => handleChange(e)}
                        placeholder={t("Others (please specify)")}
                        isInvalid={
                          dataInputError.applicationType_others ? true : false
                        }
                        invalidMessage={dataInputError.applicationType_others}
                      />
                    </Col>
                  )}
                  {(formData?.aiRequestDetails?.cloudService ===
                    "AWS Bedrock" ||
                    formData?.aiRequestDetails?.cloudService ===
                      "Azure OpenAI") && (
                    <Col
                      md={
                        formData?.aiRequestDetails?.applicationType ===
                        "Others (please specify)"
                          ? 12
                          : 6
                      }
                    >
                      <StaticDropdown
                        className={"mb-0"}
                        label={t("Type Of Environment")}
                        defaultSelectMessage="Select Environment"
                        name="typeOfEnvironment"
                        value={formData?.aiRequestDetails?.typeOfEnvironment}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        selectValues={typeOfEnvironments}
                        isInvalid={
                          dataInputError.typeOfEnvironment ? true : false
                        }
                        invalidMessage={dataInputError.typeOfEnvironment}
                      />
                      {showEnvironmentText && (
                        <div className="d-flex  justify-content-start  pb-2 mb-0 align-items-center custom-no-data-message-text margin-top--10 text-danger">
                          <span>
                            <strong>Note :</strong>
                            {` ${noteEnvironmentText}`}
                          </span>
                        </div>
                      )}
                    </Col>
                  )}
                  {(formData?.aiRequestDetails?.cloudService ===
                    "AWS Bedrock" ||
                    formData?.aiRequestDetails?.cloudService ===
                      "Azure OpenAI") && (
                    <>
                      <Col md={6}>
                        <TextInput
                          label={t("Application Name")}
                          name="applicationName"
                          value={formData?.aiRequestDetails?.applicationName}
                          onChange={(e) => handleChange(e)}
                          placeholder={t("Application Name")}
                          isInvalid={
                            dataInputError.applicationName ? true : false
                          }
                          invalidMessage={dataInputError.applicationName}
                        />
                      </Col>
                      <Col md={6}>
                        <StaticDropdown
                          label={t("Region")}
                          defaultSelectMessage="Select"
                          name="region"
                          value={formData?.aiRequestDetails?.region}
                          onChange={(e) => handleChange(e)}
                          selectValues={regionOption}
                          isInvalid={dataInputError.region ? true : false}
                          invalidMessage={dataInputError.region}
                        />
                      </Col>
                    </>
                  )}
                  <Col md={6}>
                    <StaticDropdown
                      label={t(
                        "What is the scope of users for this AI application?"
                      )}
                      defaultSelectMessage="Select"
                      name="scopeOfUsers"
                      value={formData?.aiRequestDetails?.scopeOfUsers}
                      onChange={(e) => handleChange(e)}
                      selectValues={scopeOfUsers}
                      isInvalid={dataInputError.scopeOfUsers ? true : false}
                      invalidMessage={dataInputError.scopeOfUsers}
                    />
                  </Col>
                  <Col md={6}>
                    <StaticDropdown
                      label={t(
                        "What types of data will be processed by this platform?"
                      )}
                      defaultSelectMessage="Select"
                      name="typeOfDataProcessed"
                      value={formData?.aiRequestDetails?.typeOfDataProcessed}
                      onChange={(e) => handleChange(e)}
                      selectValues={typeOfDataProcessed}
                      isInvalid={
                        dataInputError.typeOfDataProcessed ? true : false
                      }
                      invalidMessage={dataInputError.typeOfDataProcessed}
                    />
                  </Col>
                  <Col
                    md={
                      formData?.aiRequestDetails?.cloudService ===
                      "Amazon Q Business"
                        ? 6
                        : 12
                    }
                  >
                    <StaticDropdown
                      label={t("Data Type used for POC")}
                      defaultSelectMessage="Select"
                      name="dataTypeUsedForPOC"
                      value={formData?.aiRequestDetails?.dataTypeUsedForPOC}
                      onChange={(e) => handleChange(e)}
                      selectValues={dataTypeOptions}
                      isInvalid={
                        dataInputError.dataTypeUsedForPOC ? true : false
                      }
                      invalidMessage={dataInputError.dataTypeUsedForPOC}
                    />
                  </Col>
                  {formData?.aiRequestDetails?.cloudService ===
                    "Amazon Q Business" && (
                    <Col
                      md={
                        formData?.aiRequestDetails?.applicationType ===
                        "Others (please specify)"
                          ? 12
                          : 6
                      }
                    >
                      <StaticDropdown
                        className={"mb-0"}
                        label={t("Type Of Environment")}
                        defaultSelectMessage="Select Environment"
                        name="typeOfEnvironment"
                        value={formData?.aiRequestDetails?.typeOfEnvironment}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        selectValues={typeOfEnvironments}
                        isInvalid={
                          dataInputError.typeOfEnvironment ? true : false
                        }
                        invalidMessage={dataInputError.typeOfEnvironment}
                      />
                      {showEnvironmentText && (
                        <div className="d-flex  justify-content-start  pb-2 mb-0 align-items-center custom-no-data-message-text margin-top--10 text-danger">
                          <span>
                            <strong>Note :</strong>
                            {` ${noteEnvironmentText}`}
                          </span>
                        </div>
                      )}
                    </Col>
                  )}
                  <Col
                    md={`${
                      formData?.aiRequestDetails?.typeOfTasks ===
                      "Others (please specify)"
                        ? 6
                        : 12
                    }`}
                  >
                    <StaticDropdown
                      label={t(
                        "What types of tasks or business processes will this application support?"
                      )}
                      defaultSelectMessage="Select"
                      name="typeOfTasks"
                      value={formData?.aiRequestDetails?.typeOfTasks}
                      onChange={(e) => handleChange(e)}
                      selectValues={typeOfTasks}
                      isInvalid={dataInputError.typeOfTasks ? true : false}
                      invalidMessage={dataInputError.typeOfTasks}
                    />
                  </Col>
                  {formData?.aiRequestDetails?.typeOfTasks ===
                    "Others (please specify)" && (
                    <Col md={6}>
                      <TextInput
                        label={t("Others (please specify)")}
                        name="typeOfTasks_others"
                        value={formData?.aiRequestDetails?.typeOfTasks_others}
                        onChange={(e) => handleChange(e)}
                        placeholder={t("Others (please specify)")}
                        isInvalid={
                          dataInputError.typeOfTasks_others ? true : false
                        }
                        invalidMessage={dataInputError.typeOfTasks_others}
                      />
                    </Col>
                  )}

                  <Col md={6}>
                    <StaticDropdown
                      label={t("AI Usage Type")}
                      defaultSelectMessage="Select"
                      name="aiUsageType"
                      value={formData?.aiRequestDetails?.aiUsageType}
                      onChange={(e) => handleChange(e)}
                      selectValues={aiUsageTypes}
                      isInvalid={dataInputError.aiUsageType ? true : false}
                      invalidMessage={dataInputError.aiUsageType}
                    />
                  </Col>

                  <Col md={6}>
                    <StaticDropdown
                      label={t(
                        "How would outputs from the tool be used or distributed?"
                      )}
                      defaultSelectMessage="Select"
                      name="toolsDistribution"
                      value={formData?.aiRequestDetails?.toolsDistribution}
                      onChange={(e) => handleChange(e)}
                      selectValues={toolsDistribution}
                      isInvalid={
                        dataInputError.toolsDistribution ? true : false
                      }
                      invalidMessage={dataInputError.toolsDistribution}
                    />
                  </Col>
                  <Col md={6}>
                    <StaticDropdown
                      label={t(
                        "What business justification is driving this request?"
                      )}
                      defaultSelectMessage="Select"
                      name="businessJustification"
                      value={formData?.aiRequestDetails?.businessJustification}
                      onChange={(e) => handleChange(e)}
                      selectValues={businessJustifications}
                      isInvalid={
                        dataInputError.businessJustification ? true : false
                      }
                      invalidMessage={dataInputError.businessJustification}
                    />
                  </Col>
                  <Col md={6}>
                    <StaticDropdown
                      label={t("Quality Control Consent")}
                      defaultSelectMessage="Select"
                      name="qualityControlConsent"
                      value={formData?.aiRequestDetails?.qualityControlConsent}
                      onChange={(e) => handleChange(e)}
                      selectValues={qualityControlConsents}
                      isInvalid={
                        dataInputError.qualityControlConsent ? true : false
                      }
                      invalidMessage={dataInputError.qualityControlConsent}
                    />
                  </Col>
                  <Col md={6}>
                    <StaticDropdown
                      label={t(
                        "Categories of Data Subjects whose Personal Data will be processed:"
                      )}
                      defaultSelectMessage="Select"
                      name="categoryOfData"
                      value={formData?.aiRequestDetails?.categoryOfData}
                      onChange={(e) => handleChange(e)}
                      selectValues={categoryOfDataOption}
                      isInvalid={dataInputError.categoryOfData ? true : false}
                      invalidMessage={dataInputError.categoryOfData}
                    />
                  </Col>
                  <Col md={6}>
                    <StaticDropdown
                      label={
                        <>
                          {t(
                            "How would outputs from the tool be used or distributed?"
                          )}
                          <i
                            className="fa fa-info-circle px-2"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={`Refers to the results or responses generated by a generative AI system, such as text or code`}
                          ></i>
                        </>
                      }
                      defaultSelectMessage="Select"
                      name="distributedOutputTools"
                      value={formData?.aiRequestDetails?.distributedOutputTools}
                      onChange={(e) => handleChange(e)}
                      selectValues={distributedOutputToolsOption}
                      isInvalid={
                        dataInputError.distributedOutputTools ? true : false
                      }
                      invalidMessage={dataInputError.distributedOutputTools}
                    />
                  </Col>
                </>
              )}
              {isFieldEnable && (
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="form-subject">
                    <TextInput
                      as="textarea"
                      label={t(
                        "Please describe the primary use case for the AI application"
                      )}
                      name="useCaseDescription"
                      value={formData?.aiRequestDetails?.useCaseDescription}
                      onChange={(e) =>
                        handleChangeTextArea(
                          "useCaseDescription",
                          e.target.value
                        )
                      }
                      placeholder={t("Description")}
                      isInvalid={
                        dataInputError.useCaseDescription ? true : false
                      }
                      invalidMessage={dataInputError.useCaseDescription}
                    />
                  </Form.Group>
                </Col>
              )}

              {(isFieldEnable ||
                formData?.aiRequestDetails?.cloudService ===
                  "Amazon Q Developer") && (
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="form-subject">
                    <TextInput
                      as="textarea"
                      label={t(
                        "Please share anything else that could help the assessment."
                      )}
                      name="additionalComments"
                      value={formData?.aiRequestDetails?.additionalComments}
                      onChange={(e) =>
                        handleChangeTextArea(
                          "additionalComments",
                          e.target.value
                        )
                      }
                      placeholder={t("Additional Comments")}
                      isInvalid={
                        dataInputError.additionalComments ? true : false
                      }
                      invalidMessage={dataInputError.additionalComments}
                    />
                  </Form.Group>
                </Col>
              )}
              {isFieldEnable && (
                <Col md={12} className="mt-2">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      {t(
                        "Upload documents related to use case such as architecture diagrams or manuals to help the assessment."
                      )}
                    </Form.Label>
                    {/* <Form.Control
            type='file'
            ref={ref}
            onChange={(e) => {
              uploadImage(e);
            }}
          /> */}
                    <div className="custom-upload-wrapper">
                      <button className="custom-upload-button">
                        {fileLoading ? (
                          <SpinnerLoader />
                        ) : fileName ? (
                          fileName
                        ) : (
                          <>
                            <i className="fa fa-cloud-upload"></i>
                            {t("add_file")} (pdf / jpg / png)
                          </>
                        )}
                      </button>
                      <input
                        type="file"
                        ref={ref}
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                    </div>
                    {dataInputError?.supportingDocumentName && (
                      <p className="invalid-feedback d-block">
                        {dataInputError?.supportingDocumentName}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row
              className={`${
                isFieldEnable ||
                formData?.aiRequestDetails?.cloudService ===
                  "Amazon Q Developer"
                  ? "ai-form-billing-info"
                  : ""
              }`}
            >
              <>
                {/* <Row>
                  <Col md={12} className="mb-3">
                    <span className="subtitle-text">
                      {t("Account Details")}
                    </span>
                  </Col>
                  </Row>
               */}
                {/* {isFieldEnable && <hr />} */}
                {isFieldEnable ||
                  (formData?.aiRequestDetails?.cloudService ===
                    "Amazon Q Developer" && (
                    <Row>
                      <Col md={12} className="mb-3 text-center">
                        <span className="subtitle-text">
                          {t(
                            "Below information are used for chargeback purpose"
                          )}
                        </span>
                      </Col>
                    </Row>
                  ))}

                {isFieldEnable ||
                  (formData?.aiRequestDetails?.cloudService ===
                    "Amazon Q Developer" && (
                    <>
                      <Col md={12} className="mt-2">
                        <TextInput
                          label={t(
                            "Provide your cloud account number (billing details associated with this account number will be used for chargeback)"
                          )}
                          name="cloudAccountNumber"
                          value={formData?.aiRequestDetails?.cloudAccountNumber}
                          onChange={(e) => handleChange(e)}
                          placeholder={t("Cloud Account Number")}
                          isInvalid={
                            dataInputError.cloudAccountNumber ? true : false
                          }
                          invalidMessage={dataInputError.cloudAccountNumber}
                        />
                      </Col>
                      <Col md={6} className="mt-2">
                        <TextInput
                          label={t("Billing email")}
                          name="billingEmailAccoutNo"
                          value={
                            formData?.aiRequestDetails?.billingEmailAccoutNo
                          }
                          onChange={(e) => handleChange(e)}
                          placeholder={t("Billing Email")}
                          isInvalid={
                            dataInputError.billingEmailAccoutNo ? true : false
                          }
                          invalidMessage={dataInputError.billingEmailAccoutNo}
                        />
                      </Col>
                      <p className="text-center">( OR )</p>
                    </>
                  ))}
              </>

              <>
                {formData?.aiRequestDetails?.cloudService && (
                  <>
                    <Col md={6}>
                      <BillingCompanyDropdown
                        label={"*" + t("billing_op_company") + ":"}
                        defaultSelectMessage={t("select_op_company")}
                        name="billingOpco"
                        value={formData?.aiRequestDetails?.billingOpco}
                        onChange={(e) => handleChange(e)}
                        selectValues={responseData}
                        isInvalid={dataInputError.billingOpco ? true : false}
                        invalidMessage={dataInputError.billingOpco}
                      />
                    </Col>

                    <Col md={6}>
                      <BillingSubCompanyDropdown
                        label={"*" + t("billing_subop_company") + ":"}
                        defaultSelectMessage={t("select_subop_company")}
                        name="billingSubOpco"
                        value={formData?.aiRequestDetails?.billingSubOpco}
                        onChange={(e) => handleChange(e)}
                        selectValues={billingSubOpCompany}
                        isInvalid={dataInputError.billingSubOpco ? true : false}
                        invalidMessage={dataInputError.billingSubOpco}
                      />
                    </Col>

                    <Col md={6}>
                      <BillingDepartmentDropdown
                        label={"*" + t("billing_dep") + ":"}
                        defaultSelectMessage="Select Department"
                        name="billingDepartment"
                        value={formData?.aiRequestDetails?.billingDepartment}
                        onChange={(e) => handleChange(e)}
                        selectValues={billingDepartment}
                        isInvalid={
                          dataInputError.billingDepartment ? true : false
                        }
                        invalidMessage={dataInputError.billingDepartment}
                      />
                    </Col>

                    <Col md={6}>
                      <StaticDropdown
                        label={"*" + t("bill_to") + ":"}
                        defaultSelectMessage={t("bill_to_msg")}
                        name="billTo"
                        value={formData?.aiRequestDetails?.billTo}
                        onChange={(e) => handleChange(e)}
                        selectValues={staticbillto}
                        isInvalid={dataInputError.billTo ? true : false}
                        invalidMessage={dataInputError.billTo}
                      />
                    </Col>

                    {formData?.aiRequestDetails?.billTo === "GSID" ? (
                      <Col md={6}>
                        <TextInput
                          label={t("GSID")}
                          name="gsid"
                          value={formData?.aiRequestDetails?.gsid}
                          onChange={(e) => handleChange(e)}
                          placeholder={t("GSID")}
                          isInvalid={dataInputError.gsid ? true : false}
                          invalidMessage={dataInputError.gsid}
                        />
                      </Col>
                    ) : null}
                    {formData?.aiRequestDetails?.billTo ===
                      "Global IS (GISC)" && (
                      <Col md={6}>
                        <TextInput
                          label="*IO:"
                          name="billingIo"
                          value={formData?.aiRequestDetails?.billingIo}
                          onChange={(e) => handleChange(e)}
                          placeholder="IO"
                          isInvalid={dataInputError.billingIo ? true : false}
                          invalidMessage={dataInputError.billingIo}
                        />
                      </Col>
                    )}
                    {formData?.aiRequestDetails?.billTo === "Other Teams" && (
                      <Col md={6}>
                        <TextInput
                          label="*Cost Center:"
                          name="costCenter"
                          value={formData?.aiRequestDetails?.costCenter}
                          onChange={(e) => handleChange(e)}
                          placeholder="Cost Center"
                          isInvalid={dataInputError.costCenter ? true : false}
                          invalidMessage={dataInputError.costCenter}
                        />
                      </Col>
                    )}

                    <Col md={6}>
                      <StaticDropdown
                        label={t("billing_region")}
                        defaultSelectMessage="Select Region"
                        name="billingRegion"
                        value={formData?.aiRequestDetails?.billingRegion}
                        onChange={(e) => handleChange(e)}
                        selectValues={staticbillingRegion}
                        isInvalid={dataInputError.billingRegion ? true : false}
                        invalidMessage={dataInputError.billingRegion}
                      />
                    </Col>
                    <Col md={6} className="">
                      <TextInput
                        label={t("Billing email")}
                        name="billingEmail"
                        value={formData?.aiRequestDetails?.billingEmail}
                        onChange={(e) => handleChange(e)}
                        placeholder={t("Billing email")}
                        isInvalid={dataInputError.billingEmail ? true : false}
                        invalidMessage={dataInputError.billingEmail}
                      />
                    </Col>
                  </>
                )}
              </>
            </Row>
            {/* <Row>
              <Col md={6}>
                <p className="cidr-note">{t("GDPR Agreement")}</p>
              </Col>
            </Row> */}
            <Row>
              <Col md={10}>
                <Form.Group className="mb-3" controlId="disclaimerCheckbox">
                  {/* todo:rd fix for link */}
                  <Form.Check
                    type="checkbox"
                    label={
                      <div style={{ fontSize: 12 }}>
                        {t(
                          `I consent and acknowledge that I have provided all the details to the best of my knowledge by adhering to SonyAI compliance guidelines - `
                        )}
                        <a
                          href="https://sony.sharepoint.com/:b:/s/S004-GISC-Cloud/EZoBZUbXQ25DpM2dJpaf-CkB3vrZebKZ4r-fCYCCnd9-qw?e=hWL2M6"
                          target="blank"
                        >
                          Guideliness can be found here.
                        </a>
                      </div>
                    }
                    defaultChecked={isDisclaimerAgreed}
                    onChange={(e) => {
                      setIsDisclaimerAgreed(!isDisclaimerAgreed);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button
                      type="submit"
                      className={`teal-filled-btn ${
                        !isDisclaimerAgreed ? "disable-div" : ""
                      }`}
                      disabled={isDisclaimerAgreed ? false : true}
                    >
                      {t("submit")}
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestSubmit
              refUid={formSubmitRefUid}
              errorMessageValue={formSubmitError}
            />
          }
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
      {/* {showEnvironmentText && (
        <CustomModal
          fileUpload={true}
          showModal={true}
          children={
            <ShowWarningText
              closeModalParent={() => setShowEnvironmentText(false)}
              noteEnvironmentText={noteEnvironmentText}
            />
          }
        />
      )} */}
    </>
  );
};

export default NewRequest;
