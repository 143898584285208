import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { Buffer } from "buffer";
// Components
import TextInput from "components/Forms/TextInput";
import SpinnerLoader from "components/Common/SpinnerLoader";
import BillingCompanyDropdown from "components/Forms/BillingCompanyDropdown";
import BillingSubCompanyDropdown from "components/Forms/BillingSubCompanyDropdown";
import BillingDepartmentDropdown from "components/Forms/BillingDepartmentDropdown";
import StaticDropdown from "components/Forms/StaticDropdown";
import CustomModal from "components/Modal/CustomModal";
import DangerAlert from "components/Message/DangerAlert";

import NewRequestSubmit from "./NewRequestSubmit";
import NewRequestCancel from "./NewRequestCancel";
import InfoIcon from "icons/info.svg";
import Tooltip from "rc-tooltip";
import { useTranslation } from "react-i18next";

// Data
import {
  staticbillingRegion,
  staticbillto,
} from "pages/Requests/AWS/StaticData/awsStaticData";

// Helper
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";

// Validator
import { awsWorkspaceValidator } from "../validator";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const NewRequest = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const idToken = userInfo.idToken;

  // API Get State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Region Static Data
  const regionData = [
    { label: "us-east-1", value: "us-east-1" },
    { label: "ap-southeast-1", value: "ap-southeast-1" },
    { label: "eu-west-1", value: "eu-west-1" },
  ];

  // directory data
  const directoryData = [
    { label: "am.sony.com(AM)", value: "AM" },
    // { label: "sm.sony.com(Sony Music)", value: "SonyMusic" },
  ];

  const UsWestDirectoryData = [
    { label: "EU-West-1(EU)", value: "EU" },
    // { label: "EU-West-1(EU-Business)", value: "EU-Busi" },
  ];

  // Form State
  const [WOgid, setWOgid] = useState(userInfo.preferred_username);
  const [WOmail, setWOmail] = useState(userInfo.email);
  const [WOfirstName, setWOfirstName] = useState("");
  const [WOlasttName, setWOlasttName] = useState("");
  const [WOphno, setWOphno] = useState("");

  const [usergid, setusergid] = useState("");
  const [usermail, setusermail] = useState("");
  const [userfirstName, setuserfirstName] = useState("");
  const [userlasttName, setuserlasttName] = useState("");
  const [userphno, setuserphno] = useState("");

  const [BOmail, setBOmail] = useState("");
  const [BOfirstName, setBOfirstName] = useState("");
  const [BOlasttName, setBOlasttName] = useState("");
  const [BOphno, setBOphno] = useState("");

  const [billingopco, setBillingopco] = useState("");
  const [billingsubopco, setBillingsubopco] = useState("");
  const [billingdepartment, setBillingdepartment] = useState("");
  const [billto, setbillto] = useState("");
  const [io, setIo] = useState("");
  const [costcenter, setCostcenter] = useState("");
  const [billingregion, setbillingregion] = useState("");

  //gsid - workspacemode
  const [gsid, setGsid] = useState("");
  const [workSpaceMode, setWorkSpaceMode] = useState("autoStop");

  const [region, setRegion] = useState("");
  const [bundleOptions, setBundleOptions] = useState([]);
  const [bundleId, setBundleId] = useState("");
  const { t } = useTranslation();

  // Component State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);
  const [pasteToUserDetails, setpasteToUserDetails] = useState(false);
  const [pasteToBillingContact, setpasteToBillingContact] = useState(false);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  const [bundleApiLoading, setBundleApiLoading] = useState(false);
  const [bundleDescription, setbundleDescription] = useState("");

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // choose directory

  const [directory, setDirectory] = useState("");

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === "billingopco") {
      setBillingsubopco("");
      setBillingdepartment("");

      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      setBillingopco(opCompany);
      if (opCompany !== "") {
        const getSubOpCompany = responseData.find((t) => t.opco === opCompany)
          .subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === "billingsubopco") {
      setBillingdepartment("");
      setBillingDepartment([]);
      let opSubCompany = target.value;
      setBillingsubopco(opSubCompany);
      if (opSubCompany !== "") {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    if (target.name === "billingdepartment") {
      let department = target.value;
      setBillingdepartment(department);
    }
  };

  // API Form Submit State
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSubmitModal, setFormSubmitModal] = useState(false);
  const [formSubmitRefUid, setFormSubmitRefUid] = useState(null);

  // Form Submit
  const handleContinue = (e) => {
    e.preventDefault();
    const formSubmitApiUrl = `${process.env.REACT_APP_AWS_WORKSPACES_ADD}?type=add`;
    const errors = awsWorkspaceValidator({
      WOgid,
      WOmail,
      WOfirstName,
      WOlasttName,
      WOphno,
      usergid,
      usermail,
      userfirstName,
      userlasttName,
      userphno,
      BOmail,
      BOfirstName,
      BOlasttName,
      BOphno,
      billingopco,
      billingsubopco,
      billingdepartment,
      billto,
      io,
      costcenter,
      billingregion,
      region,
      bundleId,
      directory,
      gsid,
    });
    setDataInputError(errors);

    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formSubmitRequestBody = {
        WOgid,
        WOmail,
        WOfirstName,
        WOlasttName,
        WOphno,
        usergid,
        usermail,
        userfirstName,
        userlasttName,
        userphno,
        BOmail,
        BOfirstName,
        BOlasttName,
        BOphno,
        billingopco: billingopco,
        billingsupco: billingsubopco,
        billingdepartment: billingdepartment,
        billto: billto,

        io: billto === "Global IS (GISC)" ? io : "N/A",
        GSID: billto === "GSID" ? gsid : "N/A",
        costcenter: billto === "Other Teams" ? costcenter : "N/A",
        billingregion,
        region,
        bundleId,
        uid: `u-${currentEpochTime}`,
        requestoremail: userInfo.email,
        requestStatus: "Pending",
        submittedBy: userInfo.preferred_username,
        directory: directory,
      };

      postApiCall(formSubmitApiUrl, idToken, formSubmitRequestBody)
        .then((response) => {
          if (response.data.body !== "Failure") {
            setFormSubmitRefUid(currentEpochTime);
            setFormSubmitError(null);
            setFormSubmitModal(true);
          } else {
            setFormSubmitRefUid(null);
            setFormSubmitError(
              `Couldn't place the request for workspace. Please recheck the user GID.`
            );
            setFormSubmitModal(true);
          }
        })
        .catch((err) => {
          setFormSubmitError(
            `Couldn't place the request for workspace. Please recheck the user GID.`
          );
          setLoading(false);
        });
    }
  };

  // Bundle Selection API Call
  useEffect(() => {
    if (region !== "" && directory !== "") {
      setBundleApiLoading(true);
      setBundleId("");
      setbundleDescription("");
      const bundleUrl = `${process.env.REACT_APP_AWS_WORKSPACES_BUNDLE}`;
      // const bundleRequestBody = `{"region":"${region}"}`;
      const bundleRequestBody = `{"region":"${region}", "directory":"${directory}"}`;
      postApiCall(bundleUrl, idToken, bundleRequestBody).then((response) => {
        setBundleOptions(response.data);
        setBundleApiLoading(false);
      });
    }
    return () => {
      setBundleOptions([]);
    };
  }, [region, directory]);

  useEffect(() => {
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0
      }
    }`;
    const encodedString = Buffer.from(queryObj).toString("base64");
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="mt-3 mb-3">
          <span className="subtitle-text">{t("request_workspace")}</span>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <SpinnerLoader />}

        {/* {formSubmitError !== null && (
          <Row>
            <Col className='mt-2'>
              <DangerAlert message={formSubmitError} />
            </Col>
          </Row>
        )} */}

        {loading === false && (
          <>
            <Row>
              <Col md={12} className="mb-3">
                <Tooltip
                  overlayClassName="rc-tooltip-width-40"
                  placement="right"
                  overlay={<div>{t("new_request_tooltip")}</div>}
                >
                  <span className="subtitle-text">
                    {t("workspace_owner_details")}{" "}
                    <img src={InfoIcon} alt="info icon" />
                  </span>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("global_id")}
                  placeholder={t("global_id")}
                  value={WOgid}
                  onChange={(e) => setWOgid(e.target.value)}
                  isInvalid={dataInputError.WOgid ? true : false}
                  invalidMessage={dataInputError.WOgid}
                  infoBubbleTitle="A workspace owner is the person requesting the workspace and can request one or more workspaces for either him/herself or others in the team. "
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("email")}
                  name="Email Address"
                  value={WOmail}
                  onChange={(e) => setWOmail(e.target.value)}
                  placeholder={t("email")}
                  isInvalid={dataInputError.WOmail ? true : false}
                  invalidMessage={dataInputError.WOmail}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("first_name")}
                  name="First Name"
                  value={WOfirstName}
                  onChange={(e) => setWOfirstName(e.target.value)}
                  placeholder={t("first_name")}
                  isInvalid={dataInputError.WOfirstName ? true : false}
                  invalidMessage={dataInputError.WOfirstName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("last_name")}
                  name="Last Name"
                  value={WOlasttName}
                  onChange={(e) => setWOlasttName(e.target.value)}
                  placeholder={t("last_name")}
                  isInvalid={dataInputError.WOlasttName ? true : false}
                  invalidMessage={dataInputError.WOlasttName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("phone_number")}
                  name="Phone No"
                  value={WOphno}
                  onChange={(e) => setWOphno(e.target.value)}
                  placeholder={t("phone_number")}
                  isInvalid={dataInputError.WOphno ? true : false}
                  invalidMessage={dataInputError.WOphno}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Tooltip
                  overlayClassName="rc-tooltip-width-40"
                  placement="right"
                  overlay={
                    <span>
                      The workspace user is the individual to whom this
                      workspace will be assigned to for use and can only have
                      one workspace assigned. This can be a Sony employee or a
                      security approved contractor.
                    </span>
                  }
                >
                  <span className="subtitle-text">
                    {t("workspace_user_details")}{" "}
                    <img src={InfoIcon} alt="info icon" />{" "}
                  </span>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="WOCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Same as Workspace Owner"
                    defaultChecked={pasteToUserDetails}
                    onChange={(e) => {
                      setpasteToUserDetails(!pasteToUserDetails);
                      if (e.target.checked) {
                        setusergid(WOgid);
                        setusermail(WOmail);
                        setuserfirstName(WOfirstName);
                        setuserlasttName(WOlasttName);
                        setuserphno(WOphno);
                      } else {
                        setusergid("");
                        setusermail("");
                        setuserfirstName("");
                        setuserlasttName("");
                        setuserphno("");
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("global_id")}
                  placeholder={t("global_id")}
                  value={usergid}
                  onChange={(e) => setusergid(e.target.value)}
                  isInvalid={dataInputError.usergid ? true : false}
                  invalidMessage={dataInputError.usergid}
                  infoBubbleTitle={t("workspace_user_details_tooltip")}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <TextInput
                  label={t("email")}
                  name="Email Address"
                  value={usermail}
                  onChange={(e) => setusermail(e.target.value)}
                  placeholder={t("email")}
                  isInvalid={dataInputError.usermail ? true : false}
                  invalidMessage={dataInputError.usermail}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("first_name")}
                  name="First Name"
                  value={userfirstName}
                  onChange={(e) => setuserfirstName(e.target.value)}
                  placeholder={t("first_name")}
                  isInvalid={dataInputError.userfirstName ? true : false}
                  invalidMessage={dataInputError.userfirstName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("last_name")}
                  name="Last Name"
                  value={userlasttName}
                  onChange={(e) => setuserlasttName(e.target.value)}
                  placeholder={t("last_name")}
                  isInvalid={dataInputError.userlasttName ? true : false}
                  invalidMessage={dataInputError.userlasttName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("phone_number")}
                  name="Phone No"
                  value={userphno}
                  onChange={(e) => setuserphno(e.target.value)}
                  placeholder={t("phone_number")}
                  isInvalid={dataInputError.userphno ? true : false}
                  invalidMessage={dataInputError.userphno}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb-3">
                <span className="subtitle-text">Billing Contact</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="BOCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Same as Workspace Owner"
                    defaultChecked={pasteToBillingContact}
                    onChange={(e) => {
                      setpasteToBillingContact(!pasteToBillingContact);
                      if (e.target.checked) {
                        setBOmail(WOmail);
                        setBOfirstName(WOfirstName);
                        setBOlasttName(WOlasttName);
                        setBOphno(WOphno);
                      } else {
                        setBOmail("");
                        setBOfirstName("");
                        setBOlasttName("");
                        setBOphno("");
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("email")}
                  name="Email Address"
                  value={BOmail}
                  onChange={(e) => setBOmail(e.target.value)}
                  placeholder={t("email")}
                  isInvalid={dataInputError.BOmail ? true : false}
                  invalidMessage={dataInputError.BOmail}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("first_name")}
                  name="First Name"
                  value={BOfirstName}
                  onChange={(e) => setBOfirstName(e.target.value)}
                  placeholder={t("first_name")}
                  isInvalid={dataInputError.BOfirstName ? true : false}
                  invalidMessage={dataInputError.BOfirstName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("last_name")}
                  name="Last Name"
                  value={BOlasttName}
                  onChange={(e) => setBOlasttName(e.target.value)}
                  placeholder={t("last_name")}
                  isInvalid={dataInputError.BOlasttName ? true : false}
                  invalidMessage={dataInputError.BOlasttName}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t("phone_number")}
                  name="Phone No"
                  value={BOphno}
                  onChange={(e) => setBOphno(e.target.value)}
                  placeholder={t("phone_number")}
                  isInvalid={dataInputError.BOphno ? true : false}
                  invalidMessage={dataInputError.BOphno}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb-3">
                <span className="subtitle-text">{t("billing_details")}</span>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <BillingCompanyDropdown
                  label={"*" + t("billing_op_company") + ":"}
                  defaultSelectMessage={t("select_op_company")}
                  name="billingopco"
                  value={billingopco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.billingopco ? true : false}
                  invalidMessage={dataInputError.billingopco}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <BillingSubCompanyDropdown
                  label={"*" + t("billing_subop_company") + ":"}
                  defaultSelectMessage={t("select_subop_company")}
                  name="billingsubopco"
                  value={billingsubopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.billingsubopco ? true : false}
                  invalidMessage={dataInputError.billingsubopco}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <BillingDepartmentDropdown
                  label={"*" + t("billing_dep") + ":"}
                  defaultSelectMessage="Select Department"
                  name="billingdepartment"
                  value={billingdepartment}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.billingdepartment ? true : false}
                  invalidMessage={dataInputError.billingdepartment}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <StaticDropdown
                  label={"*" + t("bill_to") + ":"}
                  defaultSelectMessage={t("bill_to_msg")}
                  name="billto"
                  value={billto}
                  onChange={(e) => setbillto(e.target.value)}
                  selectValues={staticbillto}
                  isInvalid={dataInputError.billto ? true : false}
                  invalidMessage={dataInputError.billto}
                />
              </Col>
            </Row>

            {/* {billto === "GSID" ? (
              <Row>
                <Col md={6}>
                  <TextInput
                    label={t("GSID")}
                    name="gsid"
                    value={gsid}
                    onChange={(e) => setGsid(e.target.value)}
                    placeholder={t("GSID")}
                    isInvalid={dataInputError.gsid ? true : false}
                    invalidMessage={dataInputError.gsid}
                  />
                </Col>
              </Row>
            ) : null} */}
            {billto === "Global IS (GISC)" && (
              <Row>
                <Col md={6}>
                  <TextInput
                    label="*IO:"
                    name="io"
                    value={io}
                    onChange={(e) => setIo(e.target.value)}
                    placeholder="IO"
                    isInvalid={dataInputError.io ? true : false}
                    invalidMessage={dataInputError.io}
                  />
                </Col>
              </Row>
            )}
            {billto === "Other Teams" && (
              <Row>
                <Col md={6}>
                  <TextInput
                    label="*Cost Center:"
                    name="costcenter"
                    value={costcenter}
                    onChange={(e) => setCostcenter(e.target.value)}
                    placeholder="Cost Center"
                    isInvalid={dataInputError.costcenter ? true : false}
                    invalidMessage={dataInputError.costcenter}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6}>
                <StaticDropdown
                  label={t("billing_region")}
                  defaultSelectMessage="Select Region"
                  name="billingRegion"
                  value={billingregion}
                  onChange={(e) => setbillingregion(e.target.value)}
                  selectValues={staticbillingRegion}
                  isInvalid={dataInputError.billingregion ? true : false}
                  invalidMessage={dataInputError.billingregion}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb-3">
                <span className="subtitle-text">
                  {t("workspace_details_config")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <StaticDropdown
                  label={t("workspace_region")}
                  defaultSelectMessage={t("select_region")}
                  name="billingRegion"
                  value={region}
                  onChange={(e) => {
                    if (e.target.value === "ap-southeast-1") {
                      setDirectory("AP");
                      setRegion(e.target.value);
                    } else {
                      setDirectory("");
                      setRegion(e.target.value);
                    }
                  }}
                  selectValues={regionData}
                  isInvalid={dataInputError.region ? true : false}
                  invalidMessage={dataInputError.region}
                />
              </Col>
            </Row>
            {/* {region === "us-east-1" ? (
              <Row>
                <Col md={6}>
                  <StaticDropdown
                    label={t("Choose Directory:")}
                    defaultSelectMessage={t("choose directory")}
                    name="directory"
                    value={directory}
                    onChange={(e) => {
                      setDirectory(e.target.value);
                    }}
                    selectValues={directoryData}
                    isInvalid={dataInputError.directory ? true : false}
                    invalidMessage={dataInputError.directory}
                  />
                </Col>
              </Row>
            ) : null} */}

            {/* {region === "eu-west-1" ? (
              <Row>
                <Col md={6}>
                  <p className="cidr-note">
                    <span>Note: </span>{" "}
                    <span>
                      This is the dummy text for euwest billing region
                    </span>
                  </p>
                </Col>
              </Row>
            ) : null} */}
            {region === "us-east-1" || region === "eu-west-1" ? (
              <Row>
                <Col md={6}>
                  <StaticDropdown
                    label={t("Choose Directory:")}
                    defaultSelectMessage={t("choose directory")}
                    name="directory"
                    value={directory}
                    onChange={(e) => {
                      setDirectory(e.target.value);
                    }}
                    selectValues={
                      region === "us-east-1"
                        ? directoryData
                        : UsWestDirectoryData
                    }
                    isInvalid={dataInputError.directory ? true : false}
                    invalidMessage={dataInputError.directory}
                  />
                </Col>
              </Row>
            ) : null}

            {/* {region === "eu-west-1" ? (
              <Row className="mb-3">
                <label className="form-label mb-3">Workspace Mode</label>
                <Col md={2}>
                  <FormCheck
                    type="radio"
                    id={`default-radio-region`}
                    className="form-check-label"
                    label={`AutoStop`}
                    onChange={() => setWorkSpaceMode("autoStop")}
                    checked={workSpaceMode === "autoStop"}
                  />
                </Col>
                <Col md={2}>
                  <FormCheck
                    type="radio"
                    className="form-check-label"
                    id={`default-radio-region`}
                    label={`AlwaysOn`}
                    onChange={() => setWorkSpaceMode("alwaysOn")}
                    checked={workSpaceMode === "alwaysOn"}
                  />
                </Col>
              </Row>
            ) : null} */}

            {/* {region === "eu-west-1" ? (
              <Row>
                <Col md={6}>
                  <p className="cidr-note">
                    <span>Note: </span>{" "}
                    <span>
                      {workSpaceMode === "alwaysOn"
                        ? "This is the dummy text for workspace mode alwaysOn"
                        : "This is the dummy text for workspace mode autoStop"}
                    </span>
                  </p>
                </Col>
              </Row>
            ) : null} */}

            <Row>
              <Col md={6}>
                {bundleApiLoading ? (
                  <div className="mt-2 mb-3">
                    <SpinnerLoader />
                  </div>
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>{t("select_bundle")}</Form.Label>
                    <Form.Select
                      isInvalid={dataInputError.bundleId ? true : false}
                      onChange={(e) => {
                        setBundleId(e.target.value);
                        setbundleDescription(
                          bundleOptions.filter(
                            (x) => x.id === e.target.value
                          )[0].desc
                        );
                      }}
                      value={bundleId}
                    >
                      <option value="">{t("select_bundle")}</option>
                      {bundleOptions.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.Name} - {data.desc}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {dataInputError.bundleId}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              {!bundleApiLoading && (
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="bundleDescription">
                    <Form.Label>{t("buldle_desc")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ whiteSpace: "normal" }}
                      rows={5}
                      value={bundleDescription}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <p className="cidr-note">{t("newrequest_note")}</p>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Form.Group className="mb-3" controlId="disclaimerCheckbox">
                  {/* todo:rd fix for link */}
                  <Form.Check
                    type="checkbox"
                    label={
                      <div style={{ fontSize: 12 }}>
                        {t("newrequest_ack", {
                          0: <SonyLink />,
                        })}
                      </div>
                    }
                    defaultChecked={isDisclaimerAgreed}
                    onChange={(e) => {
                      setIsDisclaimerAgreed(!isDisclaimerAgreed);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="d-flex justify-content-start">
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button
                      type="submit"
                      className="teal-filled-btn"
                      disabled={isDisclaimerAgreed ? false : true}
                    >
                      {t("submit")}
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {formSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestSubmit
              refUid={formSubmitRefUid}
              errorMessageValue={formSubmitError}
            />
          }
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

function SonyLink() {
  return (
    <a
      target="_blank"
      href="https://intranet.sca.sony.com/sites/sca/files/documents/policies/information_and_communications_technology_acceptable_use_policy%20%28US%29.pdf\"
      data-saferedirecturl="https://www.google.com/url?q=https://intranet.sca.sony.com/sites/sca/files/documents/policies/information_and_communications_technology_acceptable_use_policy%2520%2528US%2529.pdf&amp;source=gmail&amp;ust=1651058982183000&amp;usg=AOvVaw07-TyhhNYHKwbxd8IGqNni"
      rel="noreferrer"
    >
      Acceptable Use Policy (sony.com)
    </a>
  );
}

export default NewRequest;
